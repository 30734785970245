<template>
  <LaporanManajemenEksternalForm mode="Ubah" module="Laporan Manajemen"> </LaporanManajemenEksternalForm>
</template>

<script>
import LaporanManajemenEksternalForm from './form';

const LaporanManajemenEksternalUpdate = {
  name: 'LaporanManajemenEksternalUpdate',
  components: { LaporanManajemenEksternalForm },
};

export default LaporanManajemenEksternalUpdate;
</script>
